<template>
  <div id="gyss">
    <Carousel>
      <b-carousel
          id="carousel-fade"
          fade
          img-height="300"
          img-width="container"
          style="text-shadow: 0px 0px 2px #000"
      >
        <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
      </b-carousel>
      <div style="height: 0">
        <b-alert class="mianbaoxie" show>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>关于水司</el-breadcrumb-item>
            <el-breadcrumb-item v-show="breadcrumb">组织机构</el-breadcrumb-item>
            <el-breadcrumb-item>{{ article.所属 }}</el-breadcrumb-item>
          </el-breadcrumb>
        </b-alert>
      </div>
    </Carousel>
    <div class="main_box">
      <b-container class="bv-example-row">
        <b-row>
          <b-col class="minnone" sm="3">
            <div>
              <el-collapse v-model="activeName" accordion>
                <b-nav vertical >
                  <b-nav-item v-for="(val,key) in gyss.二级" :key="key" @click="breadcrumbname = val.名称"><router-link :to="{name: 'gyss', params: { id: val.ID }}" >{{val.名称}}</router-link></b-nav-item>
                </b-nav>
              </el-collapse>
            </div>
          </b-col>
          <b-col sm="9">
            <div class="active_box">
              <div class="text_title">
                <h1>{{ article.标题 }}</h1>
              </div>
              <div class="text_body" v-html="article.内容">
              </div>
              <div class="text_footer">
                <span>【下载】</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";

export default {
  name: "gyss",
  components: {
    Carousel
  },
  data() {
    return {
      activeName: '1',
      breadcrumbname:'公司简介',
      breadcrumb:false,
      ID:'',
      gyss:'',
      article:'',
      images: {
        searchpng:require('../assets/bj.png'),
      }
    };
  },
  watch: {
    //监听路由地址的改变
    $route(){
      this.ID = this.$route.params.id
    },
    ID() {
      this.ID = this.$route.params.id
      console.log(this.ID)
      this.getArticle()
    }
  },
  mounted(){
    this.ID = this.$route.params.id
    document.documentElement.scrollTop = 0;
    let that = this
    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/获取二级菜单')
        .then(function (response) {
          that.gyss = response.data[3]
          console.log(that.gyss);
        })
        .catch(function (error) {
          console.log(error);
        });
    this.getImg()
  },
  methods:{
    clickcategory(index){
      this.categoryIndex = index
    },
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=5').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
    getArticle(){
      let that = this
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章类型查询最新一条数据?ID='+this.ID)
          .then(function (response) {
            console.log(response)
            that.article = response.data
            console.log(that.article)
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>

<style scoped lang="less">
.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}
.nav{
  align-items: flex-start;
  .nav-item:hover{
    background-color: #eeeeee;
  }
  .nav-item{
    width: 100%;
    text-align: initial;
    border-bottom: 1px solid #f3f3f3;
    a{
      height: 48px;
      line-height: 48px;
      background-color: #FFF;
      color: #303133;
      font-size: 13px;
      font-weight: 500;
      padding: 0;
    }
  }
}
.el-collapse-item{
  .el-collapse-item__wrap{
    .el-collapse-item__content{
      .nav{
        padding: 0 1rem;
      }
    }
  }
}
.text_title{
  border-bottom: 1px solid #f3f3f3;
}
.text_body{
  //text-align: initial;
  border-bottom: 1px solid #f3f3f3;
  margin: 1rem 0;
}
.text_footer{
  text-align: end;
}
.nav-item{
  a{
    text-align: center;
  }
}
.active_box{
  padding: 1rem;
  div{
    padding: 1rem;
    h1{
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    span{
      font-size: 0.8rem;
      color: #999;
      margin-bottom: 0.5rem;
      em{
        font-style: normal;
      }
    }
  }
}

.text_body /deep/ .ql-align-center{
  text-align: center;
}
.text_body /deep/ .ql-align-right{
  text-align: right;
}
.text_body /deep/ .ql-align-justify{
  text-align: justify;
}
.text_body /deep/ .ql-align-left{
  text-align: left;
}
</style>