<template>
<div id="ddjs">
  <Carousel>
    <b-carousel
        id="carousel-fade"
        fade
        img-height="300"
        img-width="container"
        style="text-shadow: 0px 0px 2px #000"
    >
      <b-carousel-slide
          caption="多喝热水"
          img-src="https://picsum.photos/1024/480/?image=10"
      ></b-carousel-slide>
    </b-carousel>
    <div style="height: 0">
      <b-alert class="mianbaoxie" show>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
        </el-breadcrumb>
      </b-alert>
    </div>
  </Carousel>
  <div>
    主要内容
  </div>
</div>
</template>

<script>
import Carousel from "@/components/Carousel";
export default {
  name: "ddjs",
  components: {
    Carousel
  },
  mounted(){
    document.documentElement.scrollTop = 0;
  },
}
</script>

<style scoped>
.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}
</style>