<template>
  <div>
    <Carousel>
      <b-carousel
          id="carousel-fade"
          fade
          img-height="300"
          img-width="container"
          style="text-shadow: 0px 0px 2px #000"
      >
        <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
      </b-carousel>
      <div style="height: 0">
        <b-alert class="mianbaoxie" show>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'zxfw' }">在线服务</el-breadcrumb-item>
            <el-breadcrumb-item>报装申请</el-breadcrumb-item>
          </el-breadcrumb>
        </b-alert>
      </div>
    </Carousel>
    <div class="form_box" v-loading="loading">
      <template>
        <div>
          <b-form v-if="show" @reset="onReset" @submit="onSubmit">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>个人信息</span>
              </div>
              <b-form-group id="input-group-1" class="bitian" label="申请单位:" label-for="input-1">
                <b-form-input
                    id="input-2"
                    v-model="form.sqdw"
                    placeholder="请输入申请单位"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" class="bitian" label="申请地址:" label-for="input-1">
                <b-form-input
                    id="input-2"
                    v-model="form.sqdz"
                    placeholder="请输入申请地址"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" class="bitian" label="联系人:" label-for="input-1">
                <b-form-input
                    id="input-2"
                    v-model="form.lxr"
                    placeholder="请输入联系人"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2"  class="bitian" label="联系电话:" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="form.phone"
                    placeholder="请输入联系电话"
                    type="number"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2"  class="bitian" label="申请事由:" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="form.remark"
                    placeholder="请输入申请事由"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" class="bitian" label="用水性质:" label-for="input-1">
                <b-form-select
                    id="input-4"
                    v-model="form.ysxz"
                    :options="ysxz"
                    required
                ></b-form-select>
              </b-form-group>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>证件提交</span>
              </div>
              <div class="imgs_box">
                <b-form-group id="input-group-3"  class="bitian" label="身份证正反面:" label-for="input-3">
                  <el-upload
                      action="#"
                      :limit="1"
                      ref="upload"
                      accept=".jpg,.jpeg,.png,.JPG,.PNG"
                      :http-request="httpRequest"
                      :before-upload="beforeUpload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :file-list="form.pic_sfz"
                      :on-exceed="handleExceed"
                      :on-change="loadJsonFromFile"
                      :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </b-form-group>
              </div>
            </el-card>

            <el-button type="primary" @click="submit">提交</el-button>
          </b-form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: "grfj",
  components: {
    Carousel
  },
  data() {
    return {
      form: {
        sqdw: '',
        lxr: '',
        sqdz: '',
        phone: '',
        remark: '',
        ysxz: '',
        pic_sfz: [],
      },
      huhao: [{
        text: '21000151',
        value: 21000151}],
      blr: [{
        text: '本人',
        value: '本人'},{
        text: '代理',
        value: '代理'}],
      zjlx:[{
        text: '身份证',
        value: '身份证'},{
        text: '护照',
        value: '护照'},{
        text: '军官证',
        value: '军官证'},{
        text:'驾照',
        value:'驾照'},
        {text:'户口簿',
          value:'户口簿'
        }],
      ysxz:[{
        text: '学校',
        value: '学校'},{
        text: '普通',
        value: '普通'},{
        text: '消防',
        value: '消防'},{
        text:'部队',
        value:'部队'},
        {text:'功能用户',
          value:'功能用户'
        },{text: '酒店',
          value: '酒店'},{
          text: '工厂',
          value: '工厂'},{
          text: '农村趸售',
          value: '农村趸售'},{
          text:'普通总表',
          value:'普通总表'},
        {text:'基建',
          value:'基建'
        },{text: '特种行业用水',
          value: '特种行业用水'},{
          text: '机关',
          value: '机关'},{
          text: '中铁',
          value: '中铁'},{
          text:'商业',
          value:'商业'},
        {text:'绿化',
          value:'绿化'
        },{text: '医院',
          value: '医院'},{
          text: '餐饮',
          value: '餐饮'},{
          text: '普通总表（总表使用）',
          value: '普通总表（总表使用）'},{
          text:'社区居委会',
          value:'社区居委会'},],
      show: true,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      yyzzImageUrl:'',
      zm:'',
      sfz:'',
      loading:false,
      ID:'',
      currentUser: {},
      User:[],
      images: {
        searchpng:require('../../assets/bj.png'),
      }
    }
  },
  methods: {
    httpRequest(option) {
      this.fileList.push(option)
    },
    beforeUpload(file) {
      let fileSize = file.size
      const FIVE_M= 5*1024*1024;
      //大于5M，不允许上传
      if(fileSize>FIVE_M){
        this.$message.error("最大上传5M")
        return  false
      }
      return true
    },
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=40').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
    handleExceed() {
      this.$message({ type: 'error', message: '已上传' })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    loadJsonFromFile(file, fileList) {
      this.form.pic_sfz = fileList
    },

    onSubmit(event) {
      event.preventDefault()
    },
    onReset(event) {
      event.preventDefault()
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function() {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function(error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function() {
          resolve(fileResult);
        };
      });
    },
    submit() {
      let that = this
      if (this.form.sqdw == '') {
        this.$message.error('请填写申请单位')
        return false
      }

      if (this.form.lxr == '') {
        this.$message.error('请填写联系人')
        return false
      }
      if (this.form.sqdz == '') {
        this.$message.error('请填写申请地址')
        return false
      }
      if (this.form.phone == '') {
        this.$message.error('请填写联系电话')
        return false
      }
      if (this.form.remark == '') {
        this.$message.error('请填写申请事由')
        return false
      }
      if (this.form.ysxz == '') {
        this.$message.error('请选择用水性质')
        return false
      }
      if (this.form.pic_sfz.length === 0) {
        this.$message.error('请上传身份证正反面照片')
        return false
      }

      this.getBase64(this.form.pic_sfz[0].raw).then(resBase64 => {
        that.sfz = resBase64.split(',')[1] 　　//直接拿到base64信息
      }).then(res => {
              console.log(that.sfz)
              const params = new URLSearchParams()
              params.append('sqdw', that.form.sqdw)
              params.append('lxr', that.form.lxr)
              params.append('sqdz', that.form.sqdz)
              params.append('phone', that.form.phone)
              params.append('remark', that.form.remark)
              params.append('ysxz', that.form.ysxz)
              params.append('openid', that.ID)
              params.append('pic_sfz', that.sfz)
              that.loading = true
              this.axios({
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                method: 'post',
                url: 'http://wx.xmadwater.com.cn/cs_y/andou/bzsq',
                data:params,
              }).then(res => {
                that.loading = false
                console.log(res)
                this.$message({
                  message: '提交成功，请在公众号上查看进展',
                  type: 'success'
                });
                this.$router.push('zxfw')
              })
      })
    },
    getUser(){
      this.ID = localStorage.getItem('token')
      this.axios.get('http://wx.xmadwater.com.cn/cs_y/andou/getBdUserInfo?unionid=' + this.ID).then(res => {
        this.User = res.data.obj
        console.log(this.User)
      })
    },
    change(val){
      console.log(val)
      this.form.hm = val.hm
      this.form.address = val.userbase_addr
      this.form.kh = val.kh
    }

  },
  mounted() {
    this.getUser();
    this.getImg();
  }
}
</script>

<style scoped>
.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}

.form_box {
  max-width: 700px;
  text-align: initial;
  margin: 2rem auto;
}
.imgs_box{
  display: flex;
  justify-content: space-between;
}
</style>