<template>
  <div id="lxwm">
    <Carousel>
      <b-carousel
          id="carousel-fade"
          fade
          img-height="300"
          img-width="container"
          style="text-shadow: 0px 0px 2px #000"
      >
        <b-carousel-slide
            caption="多喝热水"
            img-src="https://picsum.photos/1024/480/?image=10"
        ></b-carousel-slide>
      </b-carousel>
      <div style="height: 0">
        <b-alert class="mianbaoxie" show>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>联系我们</el-breadcrumb-item>
          </el-breadcrumb>
        </b-alert>
      </div>
    </Carousel>
    <div class="main_box">
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm="12">
            <div class="text_title">
              <h1>联系我们</h1>
            </div>
            <div class="text_body">
              <p>厦门安兜自来水有限公司</p>
              <p>地址：湖里区枋湖路59号（枋湖金中华左侧10米）</p>
              <p>24小时客户服务热线：0592-5773469</p>
              <p>网址：http://www.xxxxxxxxx.com</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";

export default {
  name: "lxwm",
  components: {
    Carousel
  },
  data() {
    return {
      activeName: '1',
      breadcrumb:false,
    };
  },
  mounted(){
    document.documentElement.scrollTop = 0;
  },
}
</script>

<style scoped lang="less">
.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}
.nav{
  align-items: flex-start;
  .nav-item:hover{
    background-color: #eeeeee;
  }
  .nav-item{
    width: 100%;
    text-align: initial;
    border-bottom: 1px solid #f3f3f3;
    a{
      height: 48px;
      line-height: 48px;
      background-color: #FFF;
      color: #303133;
      font-size: 13px;
      font-weight: 500;
      padding: 0;
    }
  }
}
.el-collapse-item{
  .el-collapse-item__wrap{
    .el-collapse-item__content{
      .nav{
        padding: 0 1rem;
      }
    }
  }
}
.text_title{
  border-bottom: 1px solid #f3f3f3;
}
.text_body{
  text-align: initial;
  margin: 1rem 0;
}
.text_footer{
  text-align: end;
}
</style>