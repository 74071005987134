import { render, staticRenderFns } from "./dwysxz.vue?vue&type=template&id=aaed33e8&scoped=true"
import script from "./dwysxz.vue?vue&type=script&lang=js"
export * from "./dwysxz.vue?vue&type=script&lang=js"
import style0 from "./dwysxz.vue?vue&type=style&index=0&id=aaed33e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaed33e8",
  null
  
)

export default component.exports