<template>
<div id="zxfw">
  <Carousel>
    <b-carousel
        id="carousel-fade"
        fade
        img-height="300"
        img-width="container"
        style="text-shadow: 0px 0px 2px #000"
    >
      <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
    </b-carousel>
    <div style="height: 0">
      <b-alert class="mianbaoxie" show>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>在线服务</el-breadcrumb-item>
        </el-breadcrumb>
      </b-alert>
    </div>
  </Carousel>
  <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="350px"
      center>
    <div>
      <wxlogin
          :appid="appid"
          :scope="'snsapi_login'"
          :theme="'black'"
          :redirect_uri="redirect_uri"
          :href='bast64css'
          rel="external nofollow"
          class="ewm"
      >
      </wxlogin>
    </div>
  </el-dialog>
  <div>
    <el-alert
        title="提交业务后请您关注官方公众号查看业务进度，如有疑问请联系客服：0592-5773469"
        type="info"
        center
        :closable="false">
    </el-alert>
    <b-container class="bv-example-row">
      <div class="cyfw">
        <div class="Tips">
          <span class="title">个人业务</span>
        </div>
        <b-container class="bv-example-row mb-3">
          <b-row>
            <b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/grfj">
                <div>
                  <img src="../assets/icon/复接.png" alt="">
                  <span>个人复接申请</span>
                </div>
              </router-link>
            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/grxh">
                <div>
                  <img src="../assets/icon/销户.png" alt="">
                  <span>个人销户申请</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/xzbg">
                <div>
                  <img src="../assets/icon/用书性质变更.png" alt="">
                  <span>个人用水性质变更</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/ztgs">
                <div>
                  <img src="../assets/icon/暂停用水.png" alt="">
                  <span>个人暂停供水</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/xxbg">
                <div>
                  <img src="../assets/icon/信息变更.png" alt="">
                  <span>个人信息变更</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/sbyb">
                <div>
                  <img src="../assets/icon/水表验表.png" alt="">
                  <span>个人水表验表</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/ghsq">
                <div>
                  <img src="../assets/icon/过户.png" alt="">
                  <span>个人过户申请</span>
                </div>
              </router-link>
            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/bzsq">
                <div>
                  <img src="../assets/icon/我要报装.png" alt="">
                  <span>我要报装</span>
                </div>
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-container>

    <b-container class="bv-example-row">
      <div class="cyfw">
        <div class="Tips">
          <span class="title">单位业务</span>
        </div>
        <b-container class="bv-example-row mb-3">
          <b-row>
            <b-col cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/qyfj">
                <div>
                  <img src="../assets/icon/复接-企业.png" alt="">
                  <span>单位复接申请</span>
                </div>
              </router-link>
            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/dwxh">
                <div>
                  <img src="../assets/icon/销户-企业.png" alt="">
                  <span>单位销户申请</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/dwysxz">
                <div>
                  <img src="../assets/icon/用书性质变更-企业.png" alt="">
                  <span>单位用水性质变更</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/dwztgs">
                <div>
                  <img src="../assets/icon/暂停用水-企业.png" alt="">
                  <span>单位暂停供水</span>
                </div>
              </router-link>
            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/dwxxbg">
                <div>
                  <img src="../assets/icon/信息变更-企业.png" alt="">
                  <span>单位信息变更</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/dwsbyb">
                <div>
                  <img src="../assets/icon/水表验表-企业.png" alt="">
                  <span>单位水表验表</span>
                </div>
              </router-link>

            </b-col>
            <b-col  cols="4" md="2" @click="User === null?centerDialogVisible = true:'' ">
              <router-link to="/dwghsq">
                <div>
                  <img src="../assets/icon/过户-企业.png" alt="">
                  <span>单位过户申请</span>
                </div>
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-container>
  </div>
</div>
</template>

<script>
import wxlogin from 'vue-wxlogin'
import Carousel from '@/components/Carousel.vue'
export default {
  name: "zxfw",
  components: {
    Carousel,
    wxlogin
  },
  data() {
    return {
      images: {
        searchpng:require('../assets/bj.png'),
      },
      User:null,
      centerDialogVisible: false,
      bast64css: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2hlaWdodDoyMDBweH0NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHh9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lfQ0KaWZyYW1lIHtoZWlnaHQ6IDMyMnB4O30NCg==',
      appid: 'wxe61e89a672036eae',
      redirect_uri: 'http://wx.xmadwater.com.cn/cs_y/andou/callBack',
    }
  },
  mounted(){
    document.documentElement.scrollTop = 0;
    this.getImg()
    this.User = localStorage.getItem('token');
  },
  methods: {
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=40').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.mianbaoxie {
  max-width: 1140px!important;
  top: -38px !important;
}

.cyfw_box{
  padding: 1rem 0;
  margin-bottom: 1rem;
}
.cyfw{
  padding-top: 1rem;
}

.Tips {
  border-bottom: 1px solid #eee;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;

.title {
  color: #333;
  font-size: 1rem;
  font-weight: bold;
}
.more{
  color: #999;
  font-size: 0.8rem;
}
}

.row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
    div{
  height: 100px;
  margin:1rem 0;
  width: 25%;
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-direction: row;
  align-items: center;
    a{
      display: block;
      width: 100%;
      height: 100%;
      color: #2c3e50;
      div{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;

        img{
          width: 30px;
          height: 30px;
        }
        span{
          margin-top: 0.5rem;
          color: #333;
          font-size: 0.8rem;
        }
      }
    }
}
}
</style>