<template>
<div id="zwgk">
  <Carousel>
    <b-carousel
        id="carousel-fade"
        fade
        img-height="300"
        img-width="container"
        style="text-shadow: 0px 0px 2px #000"
    >
      <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
    </b-carousel>
    <div style="height: 0">
      <b-alert class="mianbaoxie" show>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>企务公开</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadcrumbname }}</el-breadcrumb-item>
        </el-breadcrumb>
      </b-alert>
    </div>
  </Carousel>
  <div>
    <div class="main_box">
      <b-container class="bv-example-row">
        <b-row>
          <b-col class="minnone" md="3">
            <div>
              <el-collapse v-model="activeName" accordion>
                <b-nav vertical >
                  <b-nav-item v-for="(val,key) in qwgk.二级" :key="key" @click="breadcrumbname = val.名称"><router-link :to="{name: 'zwgk', params: { id: val.ID }}">{{val.名称}}</router-link></b-nav-item>
                </b-nav>
              </el-collapse>
            </div>
          </b-col>
          <b-col sm="9">
            <div>
              <ul>
                <li  v-for="(item,index) in listdata" :key="index">
                  <router-link :to="{name: 'article', params: { id: item.ID }}">
                  <div class="wzlist">
                    <p>{{ item.标题 }}</p>
                    <p class="date">[{{item.发布时间}}]</p>
                  </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="tiaoshu">
        </el-pagination>
      </b-container>
    </div>
  </div>
</div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
export default {
  name: "zwgk",
  components: {
    Carousel
  },
  data() {
    return {
      activeName: '1',
      breadcrumbname:'停水公告',
      breadcrumb:false,
      ID:'',
      qwgk:'',
      listdata:[],
      pageNum: 1,//当前页
      pageSize: 10,//每页显示数量
      tiaoshu: 0,//总条数
      images: {
        searchpng:require('../assets/bj.png'),
      }
    };
  },

  watch: {
    //监听路由地址的改变
    $route(){
      this.ID = this.$route.params.id
    },
    ID() {
      this.ID = this.$route.params.id
      console.log(this.ID)
      this.getlist();
    }
  },
  mounted(){
    this.ID = this.$route.params.id
    document.documentElement.scrollTop = 0;
    let that = this
    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/获取二级菜单')
        .then(function (response) {
          that.qwgk = response.data[1]
          console.log(that.qwgk);
        })
        .catch(function (error) {
          console.log(error);
        });
    this.getImg();
  },
  methods: {
    getlist(){
      let that = this
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章分页?页码='+ this.pageNum + '&最大条数=' + this.pageSize + '&所属=' + this.ID).then(res => {
        this.tiaoshu = res.data.总条数
        console.log(res.data)
        this.listdata = res.data.记录
        console.log(this.listdata);
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getlist()
    },
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=3').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}

.nav{
  align-items: flex-start;
  .nav-item:hover{
    background-color: #eeeeee;
  }
  .nav-item{
    width: 100%;
    text-align: initial;
    border-bottom: 1px solid #f3f3f3;
    a{
      height: 48px;
      line-height: 48px;
      background-color: #FFF;
      color: #303133;
      font-size: 13px;
      font-weight: 500;
      padding: 0;
    }
  }
}
.el-collapse-item{
  .el-collapse-item__wrap{
    .el-collapse-item__content{
      .nav{
        padding: 0 1rem;
      }
    }
  }
}
.nav-item{
  a{
    text-align: center;
  }
}
.wzlist{
 display: flex;
  justify-content: space-between;
  p{
    color: #333;
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .date{
    color: #999;
  }
}

</style>