<template>
  <div class="about">
    <Carousel>
      <b-carousel
          id="carousel-fade"
          fade
          img-height="300"
          img-width="container"
          style="text-shadow: 0px 0px 2px #000"
      >
        <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
      </b-carousel>
      <div style="height: 0">
        <b-alert class="mianbaoxie" show>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
          </el-breadcrumb>
        </b-alert>
      </div>
    </Carousel>
    <div class="main">
      <div class="xwzx_box">
        <b-container class="bv-example-row">
          <div class="xwbody">
            <div class="left">
              <div>
                <div class="xwlist">
                  <b-media right-align vertical-align="center">
                    <router-link v-for="(item,index) in data" :key="index" :to="{name: 'article', params: { id: item.ID }}">
                      <div class="list">
                        <span class="mt-0 mb-1 title">{{ item.标题 }}</span>
                        <div>{{item.发布时间}}</div>
                      </div>
                    </router-link>
                  </b-media>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="tiaoshu">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Xwzx',
  data() {
    return {
      data:[],
      pageNum:1,
      pageSize:10,
      tiaoshu:0,
      images: {
        searchpng:require('../assets/bj.png'),
      }
    }
  },
  components: {
    Carousel
  },
  mounted(){
    document.documentElement.scrollTop = 0;
    let that = this
    this.getlist()
    this.getImg()
    // this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章列表_所属ID?ID=39').then(res => {
    //   that.data = res.data
    //   console.log(that.data);
    // })
  },
  methods: {
    getlist(){
      let that = this
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章分页?页码='+ this.pageNum + '&最大条数=' + this.pageSize + '&所属=39').then(res => {
		this.tiaoshu = res.data.总条数
        this.data = res.data.记录
        // console.log(this.data);
      })
    },
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=33').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getlist()
    },
  }
}
</script>

<style lang="less">


.main {
  margin-top: 15px;
}

@media (max-width: 768px) {
  #photo > img ,.zhuyaoxw{
    height: 100px !important;
  }
  .m2_navs > li > a{
    font-size: 14px !important;
  }
  .m2_navs li{
    padding: 0 !important;
  }
  .more{
    font-size: 12px !important;
  }
  .left{
    width: 100% !important;
  }
  .right{
    display: none !important;
  }
}

@media (min-width: 992px) {
  .imgnone {
    display: block;
  }
}

@media (min-width: 1200px) {
  .imgnone {
    display: block;
  }
}

.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}

#container {
  width: 100%;
  height: 100%;
}

#photo {
  float: left;
  width: 40%;

  img {
    width: 100%;
    height: 360px;
  }
}

#content {
  float: right;
  width: 60%;
  background: #f2f2f2;
  padding: 20px 40px 0 40px;
  height: 100%;
}

.zhuyaoxw {
  width: 100%;
  height: 360px;

}

.xwbody {
  margin: 15px 0 15px 0;
  display: flex;
}

.left {
  width: 100%;
}


.m2_navs {
  margin-top: 14px;
  height: 43px;
  border-bottom: 3px solid #409eff;

  li {
    float: left;
    height: 40px;
    white-space: nowrap;
    padding: 0 14px;

    a {
      height: 40px;
      display: block;
      font-size: 17px;
      line-height: 40px;
      outline: none;
      color: #404145;
    }
  }

  span {
    font-size: 17px;
    line-height: 40px;
    display: inline-block;
    float: left;
    color: #ccc;
  }
}
.mb-0{
  text-align: initial;
}

.xwlist{
  margin-top: 15px;
}
.media{
  border-bottom: 1px dashed rgba(211,211,211);
  width: 100%;
}
.media-body{
  div{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.more{
  font-size: 14px !important;
}
.list{
  color: #333;
  font-size: 0.8rem;
  line-height: 1.5rem;
  div{
    color: #999;
  }
}
.xwzx_box{
  padding-bottom:1rem ;
}
</style>
