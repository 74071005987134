<template>
  <div>{{ ID }}</div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      ID:'',
    };
  },
  watch: {
    //监听路由地址的改变
    $route(){
      this.ID = this.$route.params.id
    },
    ID() {
      this.ID = this.$route.params.id
      console.log(this.ID)
    }
  },
  methods: {
    submitUpload() {
      console.log(this.ID)
    },
    goHome(){

      this.axios.get('http://wx.xmadwater.com.cn/cs_y/andou/getBdUserInfo?unionid=' + this.ID).then(res => {
        if (res.data.success === false){
          this.$alert('<p>检测到您还未进行绑定，请关注公众号：</p><p>安兜自来水</p><p>绑定后再进行相关操作</p>', '错误提示', {
            dangerouslyUseHTMLString: true
          });
          localStorage.removeItem('token');
          this.$router.replace('/zxfw')
        }else{
          this.$router.replace('/zxfw')
        }
      })
    }
  },
  mounted(){
    this.ID = this.$route.params.id
  //  存储ID到浏览器中
    localStorage.setItem('token',this.ID)
    if (localStorage.getItem('token')) {
      this.goHome()
    }
  },
}
</script>

<style scoped>

</style>