<template>
  <div id="ywly">
    <Carousel>
      <b-carousel
          id="carousel-fade"
          fade
          img-height="300"
          img-width="container"
          style="text-shadow: 0px 0px 2px #000"
      >
        <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
      </b-carousel>
      <div style="height: 0">
        <b-alert class="mianbaoxie" show>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">{{ data.所属 }}</el-breadcrumb-item>
          </el-breadcrumb>
        </b-alert>
      </div>
    </Carousel>
    <div>
      <div class="main_box">
        <b-container class="bv-example-row">
          <b-row>
            <b-col sm="12">
              <div class="active_box">
                <div class="text_title">
                  <h1>{{ data.标题 }}</h1>
                  <span>发表时间:<em>{{ data.发布时间 }}</em></span>
                </div>
                <div class="text_body" v-html="data.内容">
                </div>
                <div class="text_footer">
                  <span>【附件下载】</span>
                  <ul>
                    <li  v-for="(item,index) in fileList" :key="index">
                      <a :href="item.file" :download="item.name" > <span>{{ item.name }}</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
export default {
  name: "ywly",
  components: {
    Carousel
  },
  data() {
    return {
      activeName: '1',
      breadcrumbname:'',
      breadcrumb:false,
      categoryIndex:0,
      data:{},
      ID:'',
      imgurl:'',
      fileList: [],
      images: {
        searchpng:require('../assets/bj.png'),
      }
    };
  },
  mounted(){
    this.ID = this.$route.params.id
    document.documentElement.scrollTop = 0;
    let that = this
    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/文章详情 _ID?ID='+ this.ID).then(res => {
      console.log(res)
      that.data = res.data

      let file = res.data.文件
      for (file of file) {
        let pj = file.lx + ',' + file.内容
        let name = file.名称 + '.' + file.类型
        let wenjian = that.base64ToBlob(pj)
        console.log(wenjian)
        that.fileList.push({
          name: name,
          file: URL.createObjectURL(wenjian),
        })
      }
      console.log(that.fileList)
      this.getImg();

      // var data = res.data.文件
      // let base64 = btoa(data);
      // console.log(base64)
      // that.imgurl = "data:image/png;base64," + base64;
      // console.log(that.imgurl)
    })
  },
  watch: {
    //监听路由地址的改变
    $route(){
      this.ID = this.$route.params.id
    },
    ID() {
      this.ID = this.$route.params.id
      console.log(this.ID)
    }
  },
  methods:{
    clickcategory(index){
      this.categoryIndex = index
    },
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=47').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeUpload(file) {
      console.log(file);
      console.log(file.type);
      console.log(file.name)
    },
    // base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
  }
}
</script>

<style scoped lang="less">

.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}
.nav{
  align-items: flex-start;
  .nav-item:hover{
    background-color: #eeeeee;
  }
  .nav-item{
    width: 100%;
    text-align: initial;
    border-bottom: 1px solid #f3f3f3;
    background-color: #FFF;
    a{
      height: 48px;
      line-height: 48px;

      color: #303133;
      font-size: 13px;
      font-weight: 500;
      padding: 0;
    }
  }
}
.el-collapse-item{
  .el-collapse-item__wrap{
    .el-collapse-item__content{
      .nav{
        padding: 0 1rem;
      }
    }
  }
}
.text_title{
  border-bottom: 1px solid #f3f3f3;
}
.text_body{
  //text-align: initial;
  border-bottom: 1px solid #f3f3f3;
  margin: 1rem 0;
}
.text_footer{
  text-align: end;
}
.myactive {
  background-color: #aaa !important;
}
.active_box{
  padding: 1rem;
  div{
    padding: 1rem;
    h1{
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    span{
      font-size: 0.8rem;
      color: #999;
      margin-bottom: 0.5rem;
      em{
        font-style: normal;
      }
    }
  }
}

.text_body /deep/ .ql-align-center{
  text-align: center;
}
.text_body /deep/ .ql-align-right{
  text-align: right;
}
.text_body /deep/ .ql-align-justify{
  text-align: justify;
}
.text_body /deep/ .ql-align-left{
  text-align: left;
}

</style>