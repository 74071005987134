<template>
  <div>
    <Carousel>
      <b-carousel
          id="carousel-fade"
          fade
          img-height="300"
          img-width="container"
          style="text-shadow: 0px 0px 2px #000"
      >
        <img style="width: 100%;height: 400px" :src="images.searchpng" alt="">
      </b-carousel>
      <div style="height: 0">
        <b-alert class="mianbaoxie" show>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'zxfw' }">在线服务</el-breadcrumb-item>
            <el-breadcrumb-item>单位用水性质变更</el-breadcrumb-item>
          </el-breadcrumb>
        </b-alert>
      </div>
    </Carousel>
    <div class="form_box" v-loading="loading">
      <template>
        <div>
          <b-form v-if="show" @reset="onReset" @submit="onSubmit">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>个人信息</span>
              </div>
              <b-form-group id="input-group-1" class="bitian" label="请选择户号:" label-for="input-1">
                <el-select
                    value-key="id"
                    @change="change"
                    v-model="currentUser"
                    placeholder="请选择"
                    clearable>
                  <el-option
                      v-for="item in User"
                      :key="item.id"
                      :label="item.kh"
                      :value="item">
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group id="input-group-1" class="bitian" label="名称:" label-for="input-1">
                <b-form-input
                    id="input-2"
                    v-model="form.hm"
                    disabled
                    placeholder="请输入名称"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" class="bitian" label="用水地址:" label-for="input-1">
                <b-form-input
                    id="input-2"
                    disabled
                    v-model="form.address"
                    placeholder="请输入用水地址"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" class="bitian" label="电话:" label-for="input-1">
                <b-form-input
                    id="input-2"
                    v-model="form.phone"
                    placeholder="请输入预留电话"
                    type="number"
                    required
                ></b-form-input>
              </b-form-group>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>用水性质选择</span>
              </div>
              <b-form-group id="input-group-1" class="bitian" label="变更为:" label-for="input-1">
                <b-form-select
                    id="input-4"
                    v-model="form.yslx"
                    :options="ysxz"
                    @change="blrval"
                    required
                ></b-form-select>
              </b-form-group>
              <b-form-group id="input-group-2"  class="bitian" label="变更原因:" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="form.bgyy"
                    placeholder="请输入申请原因"
                    required
                ></b-form-input>
              </b-form-group>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>办理人</span>
              </div>
              <b-form-group id="input-group-3" class="bitian" label="办理人:"  label-for="input-3">
                <b-form-select
                    id="input-4"
                    v-model="form.blr"
                    :options="blr"
                    @change="blrval"
                    required
                ></b-form-select>
              </b-form-group>
              <b-form-group v-show="daili"  class="bitian" id="input-group-3" label="代理人姓名:"  label-for="input-3">
                <b-form-input
                    id="input-2"
                    v-model="form.dlr_name"
                    placeholder="请输入代理人姓名"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group v-show="daili"  class="bitian" id="input-group-3" label="代理人电话:"  label-for="input-3">
                <b-form-input
                    id="input-2"
                    v-model="form.dlr_phone"
                    placeholder="请输入代理人电话"
                    type="number"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group v-show="daili"  class="bitian" id="input-group-3" label="证件类型:"  label-for="input-3">
                <b-form-select
                    id="input-4"
                    v-model="form.id_type"
                    :options="zjlx"
                    @change="blrval"
                    required
                ></b-form-select>
              </b-form-group>
              <b-form-group v-show="daili"  class="bitian" id="input-group-3" label="证件号码:"  label-for="input-3">
                <b-form-input
                    id="input-2"
                    v-model="form.id_card"
                    placeholder="请输入证件号码"
                    type="number"
                    required
                ></b-form-input>
              </b-form-group>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>证件提交</span>
              </div>
              <div class="imgs_box">
                <b-form-group id="input-group-3"  class="bitian" label="户主身份证正面:" label-for="input-3">
                  <el-upload
                      action="#"
                      :limit="1"
                      ref="upload"
                      accept=".jpg,.jpeg,.png,.JPG,.PNG"
                      :http-request="httpRequest"
                      :before-upload="beforeUpload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :file-list="form.pic_id_zm"
                      :on-exceed="handleExceed"
                      :on-change="loadJsonFromFile"
                      :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </b-form-group>
                <b-form-group id="input-group-3"  class="bitian" label="户主身份证反面:" label-for="input-3">
                  <el-upload
                      action="#"
                      :limit="1"
                      ref="upload"
                      accept=".jpg,.jpeg,.png,.JPG,.PNG"
                      :http-request="httpRequest"
                      :before-upload="beforeUpload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :file-list="form.pic_id_fm"
                      :on-exceed="handleExceed"
                      :on-change="shenfenzhengfanFromFile"
                      :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </b-form-group>

              </div>
              <div class="imgs_box">
                <b-form-group id="input-group-3"  class="bitian" label="营业执照:" label-for="input-3">
                  <el-upload
                      action="#"
                      :limit="1"
                      ref="upload"
                      accept=".jpg,.jpeg,.png,.JPG,.PNG"
                      :http-request="httpRequest"
                      :before-upload="beforeUpload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :file-list="form.pic_fcz"
                      :on-exceed="handleExceed"
                      :on-change="yingyezhizhaoFromFile"
                      :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </b-form-group>
                <b-form-group id="input-group-3" label="其他证件:" label-for="input-3">
                  <el-upload
                      action="#"
                      :limit="1"
                      ref="upload"
                      accept=".jpg,.jpeg,.png,.JPG,.PNG"
                      :http-request="httpRequest"
                      :before-upload="beforeUpload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :file-list="form.pic_qtcl"
                      :on-exceed="handleExceed"
                      :on-change="qitaFromFile"
                      :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </b-form-group>
              </div>
            </el-card>


            <el-button type="primary" @click="submit">提交</el-button>
          </b-form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: "grfj",
  components: {
    Carousel
  },
  data() {
    return {
      form: {
        address: '',
        phone: '',
        hm: '',
        yslx: '',
        kh: '',
        bgyy: '',
        blr: '',
        id_type: '',
        id_card: '',
        dlr_name: '',
        dlr_phone: '',
        w_1592203999138: '',
        w_1592203999658: '',
        pic_fcz: [],
        pic_id_zm: [],
        pic_id_fm: [],
        pic_qtcl: [],
      },
      huhao: [{
        text: '21000151',
        value: 21000151}],
      blr: [{
        text: '本人',
        value: '本人'},{
        text: '代理',
        value: '代理'}],
      zjlx:[{
        text: '身份证',
        value: '身份证'},{
        text: '护照',
        value: '护照'},{
        text: '军官证',
        value: '军官证'},{
        text:'驾照',
        value:'驾照'},
        {text:'户口簿',
          value:'户口簿'
        }],
      ysxz:[{
        text: '学校',
        value: '学校'},{
        text: '普通',
        value: '普通'},{
        text: '消防',
        value: '消防'},{
        text:'部队',
        value:'部队'},
        {text:'功能用户',
          value:'功能用户'
        },{text: '酒店',
          value: '酒店'},{
          text: '工厂',
          value: '工厂'},{
          text: '农村趸售',
          value: '农村趸售'},{
          text:'普通总表',
          value:'普通总表'},
        {text:'基建',
          value:'基建'
        },{text: '特种行业用水',
          value: '特种行业用水'},{
          text: '机关',
          value: '机关'},{
          text: '中铁',
          value: '中铁'},{
          text:'商业',
          value:'商业'},
        {text:'绿化',
          value:'绿化'
        },{text: '医院',
          value: '医院'},{
          text: '餐饮',
          value: '餐饮'},{
          text: '普通总表（总表使用）',
          value: '普通总表（总表使用）'},{
          text:'社区居委会',
          value:'社区居委会'},],
      show: true,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      yyzzImageUrl:'',
      zm:'',
      sfz_zm:'',
      sfz_fm:'',
      fcz:'',
      qtcl:'',
      daili:false,
      loading:false,
      ID:'',
      currentUser: {},
      User:[],
      images: {
        searchpng:require('../../assets/bj.png'),
      }
    }
  },
  methods: {
    httpRequest(option) {
      this.fileList.push(option)
    },
    beforeUpload(file) {
      let fileSize = file.size
      const FIVE_M= 5*1024*1024;
      //大于5M，不允许上传
      if(fileSize>FIVE_M){
        this.$message.error("最大上传5M")
        return  false
      }
      return true
    },
    handleExceed() {
      this.$message({ type: 'error', message: '已上传' })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    loadJsonFromFile(file, fileList) {
      this.form.pic_id_zm = fileList
    },
    yingyezhizhaoFromFile(file, fileList) {
      this.form.pic_fcz = fileList
    },
    shenfenzhengfanFromFile(file, fileList) {
      this.form.pic_id_fm = fileList
    },
    qitaFromFile(file, fileList) {
      this.form.pic_qtcl = fileList
    },

    onSubmit(event) {
      event.preventDefault()
    },
    onReset(event) {
      event.preventDefault()
    },
    getImg(){
      this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/图片列表?所属ID=40').then(res => {
        console.log(res.data)
        if (res.data.length !== 0){
          this.images.searchpng = res.data[0].图片
        }
      })
    },
    blrval(){
      console.log(this.form.blr)
      if (this.form.blr == '代理') {
        this.daili = true
      } else {
        this.daili = false
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function() {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function(error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function() {
          resolve(fileResult);
        };
      });
    },
    submit() {
      let that = this
      if (this.form.sqyy == '') {
        this.$message.error('请填写申请原因')
        return false
      }

      if (this.form.kh == '') {
        this.$message.error('请选择户号')
        return false
      }
      if (this.form.yslx == '') {
        this.$message.error('请选择变更后的用水类型')
        return false
      }
      if (this.form.blr == '') {
        this.$message.error('请选择办理人')
        return false
      }
      if(this.form.blr === '代理'){
        if (this.form.dlr_name == '') {
          this.$message.error('请填写代理人姓名')
          return false
        }
        if (this.form.dlr_phone == '') {
          this.$message.error('请填写代理人电话')
          return false
        }
        if (this.form.id_type == '') {
          this.$message.error('请选择代理人证件类型')
          return false
        }
        if (this.form.id_card == '') {
          this.$message.error('请填写代理人证件号码')
          return false
        }
      }
      if (this.form.pic_id_zm.length == 0) {
        this.$message.error('请上传身份证正面')
        return false
      }
      if (this.form.pic_id_fm.length == 0) {
        this.$message.error('请上传身份证反面')
        return false
      }
      if (this.form.pic_fcz.length == 0) {
        this.$message.error('请上传手持身份证照片')
        return false
      }

      this.getBase64(this.form.pic_id_zm[0].raw).then(resBase64 => {
        that.sfz_zm = resBase64.split(',')[1]　　//直接拿到base64信息
      }).then(res => {
        this.getBase64(this.form.pic_fcz[0].raw).then(resBase64 => {
          that.fcz = resBase64.split(',')[1]　　//直接拿到base64信息
        }).then(res => {
          this.getBase64(this.form.pic_id_fm[0].raw).then(resBase64 => {
            that.sfz_fm = resBase64.split(',')[1]　　//直接拿到base64信息
          }).then(res => {
            if (this.form.pic_qtcl.length > 0) {
              this.getBase64(this.form.pic_qtcl[0].raw).then(resBase64 => {
                that.qtcl = resBase64.split(',')[1]　　//直接拿到base64信息
              }).then(res => {
                console.log(that.sfz_zm , that.sfz_fm , that.fcz , that.qtcl)
                const params = new URLSearchParams()
                params.append('kh', that.form.kh)
                params.append('hm', that.form.hm)
                params.append('yslx', that.form.yslx)
                params.append('phone', that.form.phone)
                params.append('address', that.form.address)
                params.append('bgyy', that.form.bgyy)
                params.append('blr', that.form.blr)
                params.append('dlr_name', that.form.dlr_name)
                params.append('dlr_phone', that.form.dlr_phone)
                params.append('id_type', that.form.id_type)
                params.append('id_card', that.form.id_card)
                params.append('pic_id_zm', that.sfz_zm)
                params.append('pic_id_fm', that.sfz_fm)
                params.append('pic_fcz', that.fcz)
                params.append('pic_qtcl', that.qtcl)
                params.append('openid', that.ID)
                that.loading = true
                this.axios({
                  headers: { 'content-type': 'application/x-www-form-urlencoded' },
                  method: 'post',
                  url: 'http://wx.xmadwater.com.cn/cs_y/andou/dwysxzbg',
                  data:params,
                }).then(res => {
                  that.loading = false
                  console.log(res)
                  this.$message({
                    message: '提交成功，请在公众号上查看进展',
                    type: 'success'
                  });
                  this.$router.push('zxfw')
                })
              })
            }else{
              console.log(that.sfz_zm , that.sfz_fm , that.fcz)
              const params = new URLSearchParams()
              params.append('kh', that.form.kh)
              params.append('yslx', that.form.yslx)
              params.append('hm', that.form.hm)
              params.append('phone', that.form.phone)
              params.append('address', that.form.address)
              params.append('bgyy', that.form.bgyy)
              params.append('blr', that.form.blr)
              params.append('dlr_name', that.form.dlr_name)
              params.append('dlr_phone', that.form.dlr_phone)
              params.append('id_type', that.form.id_type)
              params.append('id_card', that.form.id_card)
              params.append('pic_id_zm', that.sfz_zm)
              params.append('pic_fcz', that.fcz)
              params.append('pic_id_fm', that.sfz_fm)
              params.append('openid', that.ID)
              that.loading = true
              this.axios({
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                method: 'post',
                url: 'http://wx.xmadwater.com.cn/cs_y/andou/dwysxzbg',
                data:params,
              }).then(res => {
                that.loading = false
                console.log(res)
                this.$message({
                  message: '提交成功，请在公众号上查看进展',
                  type: 'success'
                });
                this.$router.push('zxfw')
              })
            }
          })
        })
      })
    },
    getUser(){
      this.ID = localStorage.getItem('token')
      this.axios.get('http://wx.xmadwater.com.cn/cs_y/andou/getBdUserInfo?unionid=' + this.ID).then(res => {
        this.User = res.data.obj
        console.log(this.User)
      })
    },
    change(val){
      console.log(val)
      this.form.hm = val.hm
      this.form.address = val.userbase_addr
      this.form.kh = val.kh
    }
  },
  mounted() {
    this.getUser();
    this.getImg();
  }
}
</script>

<style scoped>
.mianbaoxie {
  max-width: 1140px !important;
  top: -38px !important;
}

.form_box {
  max-width: 700px;
  text-align: initial;
  margin: 2rem auto;
}
.imgs_box{
  display: flex;
  justify-content: space-between;
}
</style>