<template>
<div class="jkcs">
  <div v-for="(item,index) in mydata" :key="index">
    <span @mouseenter="getdata(item.菜单.Id)">{{item.菜单.名称}}</span>
  </div>
  <div>
    <span v-for="(value,key) in data" :key="key">{{value}}</span>
  </div>

</div>
</template>

<script>
import Carousel from "@/components/Carousel";

export default {
  name: "jkcs",
  components: {
    Carousel
  },
  data() {
    return {
      mydata:'',
      data:[
            [{
            name:1
          },{
            name:2
          },{
            name:3
          }],

          [{
            name:1,
            '子数据':{
              name:123
            }
          },{
            name:2
          }],
        [{
          name:1
        },{
          name:2
        }]
      ]
    };
  },
  mounted(){
    let that = this
    this.axios.get('https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/ID-查询菜单',{
      params: {
        '所属菜单Id': 0
      }
    })
        .then(function (response) {
          // that.mydata = response.data
          // console.log(that.mydata)
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
  },
  methods:{
    getdata(i){
      let that = this
      this.axios.get(`https://sx.water-mind.com/cs_s/安兜官网/REST/创建接口1/ID-查询菜单?所属菜单Id=${i}`)
          .then(function (response) {
            // that.data = response.data
            console.log(that.data)
            // console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      console.log(i)
    }
  }
}
</script>

<style scoped>

</style>